import { Box, Container, Typography, CssBaseline, Link } from '@mui/material';
import React from 'react';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

interface SigninOidcProps {
  message?: string;
}

const SigninOidc: FunctionComponent<SigninOidcProps> = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    //oAuth.userManager.signinRedirectCallback().then(function (user) {
    //    // var url = user.state;
    //    oAuth.redirectAfterLoginSuccess(user);
    //}).catch(err => {
    //    console.log('signinSilent', err)
    //    alert('sign failed');
    //    navigate("/not-found", { replace: true });
    //});
  }, []);

  return (
    <div id="app-loader" className="app-loader">
      <div className="lds-app-load">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SigninOidc;
