import { useState, ReactElement, forwardRef, Ref } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Card,
    Link,
    TextField,
    Typography,
    Container,
    Alert,
    Slide,
    Dialog,
    Collapse,
    Button,
    Avatar,
    IconButton,
    styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import Logo from 'src/ui-bloom/components/LogoSign';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
    () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function ActivationSuccess() {
    const { t }: { t: any } = useTranslation();

    const [openAlert, setOpenAlert] = useState(true);
    const { status } = useParams();
    const navigate = useNavigate();

    return (
        <>
            <MainContent>
                <Container maxWidth="sm">
                    <Logo />
                </Container>
            </MainContent>

            <DialogWrapper
                open={true}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {}}
            >
                <Box
                    sx={{
                        px: 4,
                        pb: 4,
                        pt: 10
                    }}
                >
                    <AvatarSuccess>
                        <CheckTwoToneIcon />
                    </AvatarSuccess>

                    <Collapse in={openAlert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity={status?.toLowerCase()=="ok"?"success":"info"}
                        >
                            {t(
                                'The account activation have been completed'
                            )}
                        </Alert>
                    </Collapse>

                    <Typography
                        align="center"
                        sx={{
                            py: 2,
                            px: 1
                        }}
                        variant="h4"
                    >
                        {t(status.toLowerCase()!=="ok"? 'Thanks for confirming your email. You are all set to use our App.' : 'Either your account is already activated or acitvation code has expired. Please contact administrator for more details.')}
                    </Typography>

                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => {
                            navigate('/app/login', {replace:true});
                        }}
                    >
                        {t('Continue to login')}
                    </Button>
                </Box>
            </DialogWrapper>
        </>
    );
}

export default ActivationSuccess;