import {
  Avatar,
  Box,
  Button,
  Dialog,
  Slide,
  styled,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Warning';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AvatarWarn = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.warning.lighter};
      color: ${theme.colors.warning.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonWarn = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.warning.main};
     color: ${theme.palette.warning.contrastText};

     &:hover {
        background: ${theme.colors.warning.dark};
     }
    `
);

function WarnConfirm(props: {
  message?: string;
  okText?: string;
  cancelText?: string;
  id?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (id?: string) => void;
}) {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const closeConfirmDelete = () => {
    setOpen(false);
    props.onClose();
  };

  const handleDeleteCompleted = () => {
    props.onConfirm(props.id);
    setOpen(false);
  };

  return (
    <DialogWrapper
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={closeConfirmDelete}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarWarn>
          <CloseIcon />
        </AvatarWarn>

        <Typography
          align="center"
          sx={{
            py: 4,
            px: 6
          }}
          variant="h3"
        >
          {props.message || 'Are you sure?'}
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={closeConfirmDelete}
          >
            {props.cancelText || 'Cancel'}
          </Button>
          <ButtonWarn
            onClick={handleDeleteCompleted}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
          >
            {props.okText || 'Ok'}
          </ButtonWarn>
        </Box>
      </Box>
    </DialogWrapper>
  );
}

export default WarnConfirm;
